exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-eastbourne-carnival-js": () => import("./../../../src/pages/about-eastbourne-carnival.js" /* webpackChunkName: "component---src-pages-about-eastbourne-carnival-js" */),
  "component---src-pages-all-sponsors-js": () => import("./../../../src/pages/all-sponsors.js" /* webpackChunkName: "component---src-pages-all-sponsors-js" */),
  "component---src-pages-background-and-event-information-js": () => import("./../../../src/pages/background-and-event-information.js" /* webpackChunkName: "component---src-pages-background-and-event-information-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-entrant-form-js": () => import("./../../../src/pages/entrant-form.js" /* webpackChunkName: "component---src-pages-entrant-form-js" */),
  "component---src-pages-further-information-js": () => import("./../../../src/pages/further-information.js" /* webpackChunkName: "component---src-pages-further-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-sponsorship-opportunities-js": () => import("./../../../src/pages/sponsorship-opportunities.js" /* webpackChunkName: "component---src-pages-sponsorship-opportunities-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-volunteer-form-js": () => import("./../../../src/pages/volunteer-form.js" /* webpackChunkName: "component---src-pages-volunteer-form-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

